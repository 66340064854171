exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-cinemas-tsx": () => import("./../../../src/pages/cinemas.tsx" /* webpackChunkName: "component---src-pages-cinemas-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-opsaetning-af-subreader-school-tsx": () => import("./../../../src/pages/opsaetning-af-subreader-school.tsx" /* webpackChunkName: "component---src-pages-opsaetning-af-subreader-school-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-schools-tsx": () => import("./../../../src/pages/schools.tsx" /* webpackChunkName: "component---src-pages-schools-tsx" */),
  "component---src-pages-sign-in-email-tsx": () => import("./../../../src/pages/sign-in/email.tsx" /* webpackChunkName: "component---src-pages-sign-in-email-tsx" */),
  "component---src-pages-sign-in-phone-tsx": () => import("./../../../src/pages/sign-in/phone.tsx" /* webpackChunkName: "component---src-pages-sign-in-phone-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("./../../../src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-sign-in-verification-tsx": () => import("./../../../src/pages/sign-in/verification.tsx" /* webpackChunkName: "component---src-pages-sign-in-verification-tsx" */),
  "component---src-pages-subreader-ambassadoer-tsx": () => import("./../../../src/pages/subreader-ambassadoer.tsx" /* webpackChunkName: "component---src-pages-subreader-ambassadoer-tsx" */),
  "component---src-pages-subscription-monthly-tsx": () => import("./../../../src/pages/subscription/monthly.tsx" /* webpackChunkName: "component---src-pages-subscription-monthly-tsx" */),
  "component---src-pages-subscription-success-index-tsx": () => import("./../../../src/pages/subscription/success/index.tsx" /* webpackChunkName: "component---src-pages-subscription-success-index-tsx" */),
  "component---src-pages-subscription-yearly-tsx": () => import("./../../../src/pages/subscription/yearly.tsx" /* webpackChunkName: "component---src-pages-subscription-yearly-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

